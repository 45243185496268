import { Controller } from '@hotwired/stimulus'
import { Modal } from 'tailwindcss-stimulus-components'

export default class extends Controller {
  static outlets = ['modal']

  declare readonly modalOutlet: Modal

  open (e: Event): void {
    this.modalOutlet.open(e)
    this.modalOutlet.context.scope.element.focus()
  }
}
