import { Modal } from 'tailwindcss-stimulus-components'

export default class extends Modal {
  open (): void {
    super.open()
    document.body.style.overflow = 'hidden'
  }

  close (): void {
    super.close()
    document.body.style.overflow = ''
  }
}
